import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import CuboidClapper from '../components/CuboidClapper';
// import CuboidSaucer from '../components/CuboidSaucer';

class Writing extends Component {

    state = {
        activeSelection: 'episodes',
        navOpen: false
    };

    setActiveSelection = (setter) => {
        this.setState({navOpen: false});

        if (setter === 'episodes') {
            document.querySelector('.parallaxWrap').scrollTo({top: 0, left: 0, behavior: 'smooth'});
        } else {
            document.querySelector('.parallaxWrap').scrollTo({
                top: document.getElementById(setter).offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        }
    };

    toggleSideNav = () => {
        this.setState({navOpen: !this.state.navOpen});
    };

    onMountFeatureDetection = () => {
        if ("IntersectionObserver" in window) {

        const scrollObserver = new IntersectionObserver((entries, scrollObserver) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    return;
                } else {
                    this.setState({activeSelection: entry.target.id});
                }
            });
        }, {threshold: 0.99});

        const parallaxScrollItems = document.querySelectorAll('.selection');
        parallaxScrollItems.forEach(item => {
            scrollObserver.observe(item);
        });

        };
    };

    componentDidMount = () => {
        this.onMountFeatureDetection();
    };
    

    render() {
        return (
            <section className='writingWrapper'>

                <div className='parallaxWrap' >

                    {/* <figure className="globe"></figure> */}
                    <img className='bgItem' src='https://media0.giphy.com/media/LMKhaprpGFwSqeK8NX/giphy.gif' alt='wave'></img>
                    <img className='bgItem' src='https://media2.giphy.com/media/3ov9k1tL9EDPDlKlPi/giphy.gif' alt='carGif'></img>
                    <img className='bgItem' src='https://media3.giphy.com/media/3o7aDd5SyswjQEAbtK/giphy.gif' alt='allSeeingEye'></img>
                    <img className='bgItem' src='https://thumbs.gfycat.com/SimilarIdleAmericanalligator-size_restricted.gif' alt='horsey'></img>

                    {/* {this.props.width < 1025 ?  */}
                    <>
                    {/* <div className='sideNavBtn' onClick={()=>this.toggleSideNav()}>
                        {this.state.navOpen ? '✖️' : '🌎'}
                    </div> */}
                    <aside className={`writingOptions ${this.state.navOpen ? 'navopening' : 'navclosing'}`}>
                        <ul>
                        <li onClick={()=>this.setActiveSelection('spacecoast')} dataContent={this.state.activeSelection === 'spacecoast' ? '📍' : ''}>spacecoast</li>
                            <li onClick={()=>this.setActiveSelection('episodes')} dataContent={this.state.activeSelection === 'episodes' ? '📍' : ''}>episodes</li>
                            <li onClick={()=>this.setActiveSelection('wyo')} dataContent={this.state.activeSelection === 'wyo' ? '📍' : ''}>wyoming</li>
                            <li onClick={()=>this.setActiveSelection('nqa')} dataContent={this.state.activeSelection === 'nqa' ? '📍' : ''}>no questions asked</li>
                        </ul>
                        <div className='beamSkew'></div>
                    </aside>
                    </>
                    {/* :
                    // <aside className='writingOptions'>
                    //     <ul>
                    //         <li onClick={()=>this.setActiveSelection('episodes')} dataContent={this.state.activeSelection === 'episodes' ? '📍' : ''}>episodes</li>
                    //         <li onClick={()=>this.setActiveSelection('nqa')} dataContent={this.state.activeSelection === 'nqa' ? '📍' : ''}>no questions asked</li>
                    //         <li onClick={()=>this.setActiveSelection('spacecoast')} dataContent={this.state.activeSelection === 'spacecoast' ? '📍' : ''}>spacecoast</li>
                    //         <li onClick={()=>this.setActiveSelection('wyo')} dataContent={this.state.activeSelection === 'wyo' ? '📍' : ''}>wyoming</li>
                    //     </ul>
                    // </aside>
                    <></>
                    } */}
                        <div className='selection s1' id='spacecoast'>
                            <h1>spacecoast</h1>
                            <Link to='/writing/spacecoast'><p>text</p></Link>
                        </div>
                        <div className='selection s2' id='episodes'>
                            <h1>episodes</h1>
                            <Link to='/writing/noquestionsasked'><p>text</p></Link>
                        </div>
                        <div className='selection s3' id='wyo'>
                            <h1>untitled wyoming project</h1>
                            <Link to='/writing/wyoming'><p>text</p></Link>
                        </div>
                        <div className='selection s4' id='nqa'>
                            <h1>no questions asked</h1>
                            <Link to='/writing/noquestionsasked'><p>text</p></Link>
                        </div>

                        <img className='parallaxStars' src='https://i.imgur.com/hX807cH.png'></img>
                        <img className='parallaxStars parallaxStars2' src='https://i.imgur.com/RG4ifiW.png'></img>
                    </div>

                {/* {this.props.width < 1025 ? 
                <></>
                :
                <CuboidSaucer fixed={true} toggleSideNav={this.toggleSideNav} />
                } */}
                
                <div className='miniMenu'>
                    <CuboidClapper mini={true} activeHover={'back'} width={this.props.width} height={this.props.height} />
                </div>

            </section>
        )
    }
};

export default Writing;