import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ImgSpotlite from '../ImgSpotlite';
import albums from '../../imgurlinks';
import './viewalbum.css';

function ViewAlbum(props) {

    const [state, setState] = useState({spotlite: ''});

    const handleParentState = (state, value) => {
        setState({ [state]: value })
    };

    let { albumId } = useParams();
    let history = useHistory();

    const handleSpotlite = (pusher) => {
        setState({spotlite: pusher});
    };

    return ( 
    <section className='albumswrap slideanim'>

        <nav className='navback' style={{color: props.color}}>
            <i className="fa fa-chevron-left" onClick={()=> state.spotlite === '' ? history.push('/portfolio') : setState({spotlite: ''})}></i>
            <div onClick={()=> state.spotlite === '' ? history.goBack() : setState({spotlite: ''})}>
                {state.spotlite === '' ?
                    <p>Albums</p>
                    :
                    <></>
                }
            </div>
        </nav>

        {state.spotlite === '' ?
        <div className='collection'>
            <h2>{albums[albumId].title}</h2>
            
            {albumId === 'montegobay' || albumId === 'theatrics' || albumId === 'brainpuddle' || albumId === 'vibemaya' || albumId === 'montegobts' ?
                <iframe key={Math.random()} src={albums[albumId].data} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                :
                albums[albumId].data.map((element, i) => {
                    return <div key={i}
                    onClick={()=>handleSpotlite(element)}

                    >
                        <img src={element} style={{maxWidth: '100%', maxHeight: '100%'}}/>
                    </div>
                })
            }

        </div>
        :
        <ImgSpotlite handleParentState={handleParentState} imgsrc={state.spotlite} images={albums[albumId].data} activeSlideKey={albums[albumId].data.indexOf(state.spotlite)} />
        }


    </section>
    );
};

export default ViewAlbum;