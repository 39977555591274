import React, { Component } from 'react';
import { PopupText } from 'react-calendly';
import { Link } from 'react-router-dom';
import '../App.css';

class Booking extends Component {

    state = {
        currentView: ''
    };

    views = {
        allApts: 
            <>
            <div className='scheduleOption'>
                <div className='lOption'>
                    <Link to='/booking/styling'><h1>styling</h1></Link>
                </div>
            <div className='rOption'>
                <Link to='/booking/styling'>
                    <div>more</div>
                </Link>
            </div>
            </div>

            <div className='scheduleOption'>
            <div className='lOption'>
                    <Link to='/booking/writing'><h1>writing</h1></Link>
                </div>
            <div className='rOption'>
                <Link to='/booking/writing'>
                    <div>more</div>
                </Link>
            </div>
            </div>

            <div className='scheduleOption'>
            <div className='lOption'>
                    <Link to='/booking/photofilm'><h1>photo/film</h1></Link>
                </div>
            <div className='rOption'>
                <Link to='/booking/photofilm'>
                    <div>more</div>
                </Link>
            </div>
            </div>
            </>,
        styling: 
            <>
            <div className='scheduleOption'>
                <h1>consultation</h1>
                <div className='pencil noPad'>
                    <PopupText text='schedule' url='https://calendly.com/casscellina/style-consultation' styles={{
                        background: 'rgb(65, 105, 225)', color: 'rgb(255, 255, 255)', padding: '2vmin',
                        fontSize: '3vmin', height: 'fit-content', transition: 'all 200ms'
                    }}/>
                </div>
            </div>

            <div className='scheduleOption'>
                <h1>half day</h1>
                <div className='pencil noPad'>
                    <PopupText text='schedule' url='https://calendly.com/casscellina/style' styles={{
                        background: 'rgb(65, 105, 225)', color: 'rgb(255, 255, 255)', padding: '2vmin',
                        fontSize: '3vmin', height: 'fit-content', transition: 'all 200ms'
                    }}/>
                </div>
            </div>

            <div className='scheduleOption'>
                <h1>full day</h1>
                <div className='pencil noPad'>
                    <PopupText onMouseOver={this.handleHover} text='schedule' url='https://calendly.com/casscellina/stylist-1' styles={{
                        background: 'rgb(65, 105, 225)', color: 'rgb(255, 255, 255)', padding: '2vmin',
                        fontSize: '3vmin', height: 'fit-content', transition: 'all 200ms'
                    }}/>
                </div>
            </div>

            <div className='scheduleOption'>
                <h1>personal shopper</h1>
                <div className='pencil noPad'>
                    <PopupText onMouseOver={this.handleHover} text='schedule' url='https://calendly.com/casscellina/personal-shopper' styles={{
                        background: 'rgb(65, 105, 225)', color: 'rgb(255, 255, 255)', padding: '2vmin',
                        fontSize: '3vmin', height: 'fit-content', transition: 'all 200ms'
                    }}/>
                </div>
            </div>

            <div className='scheduleOption'>
                <h1>closet cleanout</h1>
                <div className='pencil noPad'>
                    <PopupText onMouseOver={this.handleHover} text='schedule' url='https://calendly.com/casscellina/closet-cleanout' styles={{
                        background: 'rgb(65, 105, 225)', color: 'rgb(255, 255, 255)', padding: '2vmin',
                        fontSize: '3vmin', height: 'fit-content', transition: 'all 200ms'
                    }}/>
                </div>
            </div>
            </>,
        writing: 
            <div className='scheduleOption'>
            <h1>consultation</h1>
            <div className='pencil noPad'>
                <PopupText text='schedule' url='https://calendly.com/casscellina/writing-consultation' styles={{
                    background: 'rgb(65, 105, 225)', color: 'rgb(255, 255, 255)', padding: '2vmin',
                    fontSize: '3vmin', height: 'fit-content', transition: 'all 200ms'
                }}/>
            </div>
            </div>,
        photofilm: 
            <>
            <div className='scheduleOption'>
                <h1>creative director</h1>
                <div className='pencil noPad'>
                    <PopupText text='schedule' url='https://calendly.com/casscellina/creativedirection' styles={{
                        background: 'rgb(65, 105, 225)', color: 'rgb(255, 255, 255)', padding: '2vmin',
                        fontSize: '3vmin', height: 'fit-content', transition: 'all 200ms'
                    }}/>
                </div>
            </div>

            <div className='scheduleOption'>
                <h1>director</h1>
                <div className='pencil noPad'>
                    <PopupText text='schedule' url='https://calendly.com/casscellina/video-shoot-0-8-hr' styles={{
                        background: 'rgb(65, 105, 225)', color: 'rgb(255, 255, 255)', padding: '2vmin',
                        fontSize: '3vmin', height: 'fit-content', transition: 'all 200ms'
                    }}/>
                </div>
            </div>

            <div className='scheduleOption'>
                <h1>producer</h1>
                <div className='pencil noPad'>
                    <PopupText text='schedule' url='https://calendly.com/casscellina/8-14' styles={{
                        background: 'rgb(65, 105, 225)', color: 'rgb(255, 255, 255)', padding: '2vmin',
                        fontSize: '3vmin', height: 'fit-content', transition: 'all 200ms'
                    }}/>
                </div>
            </div>

            </>
    };

    componentDidMount = () => {
        this.setState({currentView: this.views[this.props.bookingOption]});
    };

    render() {
        return (
            <section className='bookingWrapper'>
                <div className='schedulingOptions'>

                    <header>
                        <h1>Pencil Me In</h1>
                        <p>credediocassi@gmail.com</p>
                    </header>

                    {this.state.currentView}

                    {this.state.currentView !== this.views.allApts ?
                    <Link to='/booking'>
                        <div className='backButt'><i className="fa fa-chevron-left"></i></div>
                    </Link>
                    :
                    <></>
                    }

                </div>

                <Link to='/menu'>
                    <img src='https://i.imgur.com/VQEbQO5.png'></img>
                </Link>

            </section>
        );
    };
};

export default Booking;