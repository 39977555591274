import React, { Component } from 'react';
import '../App.css';
// import CuboidTv from '../components/CuboidTv';
import CuboidClapper from '../components/CuboidClapper';

class Film extends Component {

    filmData = [
        {
            title: 'Film One',
            vidEmbed: "https://www.youtube.com/embed/m_i4W-ofOSQ",
            role: 'role film 1',
            experience: 'personal experience film 1',
            bgColor: 'rgba(35, 32, 230, 0.618)',
            iframeColor: 'rgba(92, 13, 156, 0.898)'
        },
        {
            title: 'Film Two',
            vidEmbed: "https://www.youtube.com/embed/OGwf5MQyqvM",
            role: 'role film 2',
            experience: 'personal experience film 2',
            bgColor: 'rgba(32, 230, 210, 0.618)',
            iframeColor: 'rgba(13, 156, 94, 0.898)'
        },
        {
            title: 'Film Three',
            vidEmbed: "https://www.youtube.com/embed/i9KkBlvaBzE",
            role: 'role film 3',
            experience: 'personal experience film 3',
            bgColor: 'rgba(230, 32, 230, 0.618)',
            iframeColor: 'rgba(156, 13, 108, 0.898)'
        },
        {

        }
    ];

    state = {
        activeDisplay: this.filmData[0],
        activeLink: this.filmData[0].title
    };

    handleActiveDisplay = (dataIndex) => {
        if (this.state.activeDisplay !== this.filmData[dataIndex]) {
            this.setState({activeDisplay: this.filmData[dataIndex], activeLink: this.filmData[dataIndex].title});
        } else {
            return;
        }
    };

    render() {
        return (
            <section className='filmWrapper'>
                <div className='filmLayover' >
                    <div className='topHead'>
                        <ul>
                            <li onClick={()=>this.handleActiveDisplay(0)} style={this.state.activeLink === 'Film One' ? {background: 'white', color: 'black'} : {}}>Film One</li>
                            <li onClick={()=>this.handleActiveDisplay(1)} style={this.state.activeLink === 'Film Two' ? {background: 'white', color: 'black'} : {}}>Film Two</li>
                            <li onClick={()=>this.handleActiveDisplay(2)} style={this.state.activeLink === 'Film Three' ? {background: 'white', color: 'black'} : {}}>Film Three</li>
                        </ul>
                    </div>

                    <div className='filmDisplay'>
                        <h1>{this.state.activeDisplay.title}</h1>
                        <div className='vidFlex'>
                        {this.props.width < 1367 ?
                            <>
                            <div className='afterIframe' style={{background: this.state.activeDisplay.iframeColor}}>
                                <iframe key={Math.random()} src={this.state.activeDisplay.vidEmbed} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div className='descFlex'>
                                <p>{this.state.activeDisplay.role}</p>
                                <p>{this.state.activeDisplay.experience}</p>
                            </div>
                            </>
                            :
                            <>
                            <div className='descFlex'>
                                <p>{this.state.activeDisplay.role}</p>
                                <p>{this.state.activeDisplay.experience}</p>
                            </div>
                            <div className='tvHolder'>
                                {/* <CuboidTv>
                                    <div className='afterIframe' style={{background: this.state.activeDisplay.iframeColor}}>
                                        <iframe key={Math.random()} src={this.state.activeDisplay.vidEmbed} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                </CuboidTv> */}
                            </div>
                            </>
                            }
                        </div>
                    </div>

                    <div className='miniMenu'>
                        <CuboidClapper mini={true} activeHover={'back'} width={this.props.width} height={this.props.height} />
                    </div>

                    {/* {this.props.width < 1200 ?
                    <></>
                    :
                    <img src='https://media.giphy.com/media/YPUu1RfJd5TN7Z8zQn/source.gif'></img>
                    } */}

                </div>
            </section>
        )
    }
};

export default Film;