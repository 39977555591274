import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CuboidClapper from '../CuboidClapper';
import CuboidSaucer from '../CuboidSaucer';
import ThreeDee from '../ThreeDee';
import './menu.css';
import LOGO from '../../assets/LOGO/logo100.png';
const desktopvideo = 'https://cassbucket.s3.us-east-2.amazonaws.com/desktop_final02.mp4';
const cassLogo = 'https://i.imgur.com/b0G24DM.png';

class Menu extends Component {

    state = {
        activeHover: this.props.cass ? this.props.cass : 'home',
        videoEnded: false
    };

    handleMenuState = (state, value) => {
        this.setState({ [state]: value })
    };

    handleActiveHover = (hovData) => {
        this.setState({activeHover: hovData});
    };

    handleVidEnd = (e) => {
        this.setState({videoEnded: true});
    };

    importAll(r) {
        let images = {};
        r.keys().map((item, index) => { 
            images[item.replace('./', '')] = r(item); 
        });
        // console.log(Object.keys(images));
        // let activeFrame = Object.keys(images)[10];
        // console.log(activeFrame);
        // this.setState({activeFrame: activeFrame});
        return this.logoSequence(images)
    };

    logoSequence = (images) => {
        let timer = 0;
        let interval = setInterval(() => {
            if (timer === 126) {
                clearInterval(interval);
            } else {
                timer++;
                this.setState({activeFrame: images[`logo${timer}.png`].default});
                // console.log(timer);
            }
        }, 100);
        // this.setState({activeFrame: images[`logo${timer}.png`].default});
    };

    componentDidMount = () => {
        
        this.importAll(require.context('../../assets/LOGO', false, /\.(png|jpe?g|svg)$/));

    const rightNow = Date.now();
        if (localStorage.visitTime) {
            if (rightNow - localStorage.visitTime > 3600000) {
                localStorage.clear();
            }
        }
        if (localStorage.visited) {
            this.setState({showLoader: false});
        } else {
            localStorage.visited = true;
            localStorage.visitTime = Date.now();
            this.setState({showLoader: true});
        }
    };

    audio = document.getElementById('audi');

    render() {
        return (
            <div className='transition'>

            <div className='show3d'>
                <ThreeDee width={this.props.width} height={this.props.height} showLoader={this.state.showLoader} handleVidEnd2={this.handleVidEnd} />
            </div>
            
            <section className='menuWrap'>
                <CuboidSaucer />

                <div className={this.state.showLoader === false || this.state.videoEnded === true ? 'menuZindex animWrap' : 'menuZindex'}>

                <div className={this.state.showLoader === false || this.state.videoEnded === true ? 'hideAnim' : 'LargeAnimLoadScreen'}>
                {this.state.showLoader === true ?
                <>
                <video src={desktopvideo} autoPlay playsInline muted onEnded={this.handleVidEnd}></video>
                <audio src='https://cassbucket.s3.us-east-2.amazonaws.com/visualopening.mp3' autoPlay loop id="audi"/>
                </>
                :
                <></>}
                </div>

                <img className='headLogo' src={cassLogo} />

                    <div className='menuInternal'>

                        <div datacontent='ABOUT' className='icon' onMouseOver={()=>this.handleActiveHover('ABOUT')}>
                            <Link to='/about'>
                                <p>📲</p>
                            </Link>
                        </div>
                        <div datacontent='PORTFOLIO' className='icon' onMouseOver={()=>this.handleActiveHover('PORTFOLIO')}>
                            <Link to='/portfolio'>
                                <p>📁</p>
                            </Link>
                        </div>
                        <div datacontent='STYLING' className='icon' onMouseOver={()=>this.handleActiveHover('STYLING')}>
                            <Link to='/styling'>
                                <img src='https://i.imgur.com/aLZHjYs.png' alt=''></img>
                            </Link>
                        </div>
                        <div datacontent='BOOKING' className='icon' onMouseOver={()=>this.handleActiveHover('BOOKING')}>
                            <Link to='/booking'>
                                <p>📅</p>
                            </Link>
                        </div>

                    </div>

                </div>

            </section>

            </div>
        );
    };
};

export default Menu;