import React from 'react';
import '../App.css';
import IPhoneLayout from '../components/IPhoneLayout';
function Portfolio() {

    return (
        <section className='portfolioWrapper'>
            <IPhoneLayout />
        </section>
    );

};

export default Portfolio;