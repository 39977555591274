import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useHistory } from 'react-router-dom';
import Albums from '../Albums';
import ViewAlbum from '../ViewAlbum';
import ViewAll from '../ViewAll';
import './ipl.css';

function IPhoneLayout() {

    const tabSRCs = {
        writing: {
            inactive: 'https://i.imgur.com/4EtYHCB.png',
            active: 'https://i.imgur.com/GuufybS.png'
        },
        videos: {
            inactive: 'https://i.imgur.com/KGVD56k.png',
            active: 'https://i.imgur.com/7PTb5EE.png'
        },
        photos: {
            inactive: 'https://i.imgur.com/gV53te4.png',
            active: 'https://i.imgur.com/0cjof1I.png'
        },
        back: {
            inactive: 'https://i.imgur.com/uyKvl4c.png',
            active: 'https://i.imgur.com/7w3K9Hk.png'
        }
    };

    const sliced = useHistory().location.pathname.slice(11);

    const [state, setState] = useState({
        activeTab: sliced === '' ? 'photos' : sliced,
        scrolled: false
    });

    const handleActiveTab = (whichTab) => {
        setState({
            activeTab: whichTab
        });
    };

    const handleScroll = (e) => {
        if (e.target.scrollTop <= 50) {
            setState({scrolled: false, activeTab: state.activeTab});
        } else {
            setState({scrolled: true, activeTab: state.activeTab});
        };
    };

        return (
            <section className='iphonewrap' onScroll={(e)=>handleScroll(e)}>
                <header 
                style={state.scrolled ? 
                {color: 'white',
                background: 'linear-gradient(rgba(0, 0, 0, 0.705), transparent)'
                } : 
                {color: 'white',
                background: 'transparent'
                }}>
                    <div>
                        <img src='https://i.imgur.com/l1Jnt2P.png'></img>
                        <img src='https://i.imgur.com/xCMu6FF.png'></img>
                    </div>
                    <div>11:11 PM</div>
                    <div>
                        27%
                        <img src='https://i.imgur.com/xvQ5DEf.png'></img>
                    </div>
                </header>

                <Router>
                <article>
                    <Switch>
                        <Route exact path={['/portfolio', '/portfolio/photos']}>
                            <Albums which='Photos' />
                        </Route>

                        <Route exact path='/portfolio/videos'>
                            <Albums which='Videos' />
                        </Route>

                        <Route exact path='/portfolio/writing'>
                            <Albums which='Writing' />
                        </Route>

                        <Route exact path={['/portfolio/photos/:albumId', '/portfolio/videos/:albumId', '/portfolio/writing/:albumId']}>
                            <ViewAlbum color={state.scrolled ? 'white' : 'rgb(0, 122, 255)'} />
                        </Route>

                    </Switch>
                </article>
                </Router>
                
                <footer>

                    <Link to='/portfolio/writing'>
                        <div className='footbutt' onClick={()=>handleActiveTab('writing')}>
                            <img src={state.activeTab === 'writing' ? tabSRCs.writing.active : tabSRCs.writing.inactive}></img>
                            <p style={state.activeTab === 'writing' ? {color: 'rgb(0, 122, 255)'} : {color: 'rgb(152,152,152)'}}>Writing</p>
                        </div>
                    </Link>

                    <Link to='/portfolio/videos'>
                        <div className='footbutt' onClick={()=>handleActiveTab('videos')}>
                            <img src={state.activeTab === 'videos' ? tabSRCs.videos.active : tabSRCs.videos.inactive}></img>
                            <p style={state.activeTab === 'videos' ? {color: 'rgb(0, 122, 255)'} : {color: 'rgb(152,152,152)'}}>Videos</p>
                        </div>
                    </Link>

                    <Link to='/portfolio/photos'>
                        <div className='footbutt' onClick={()=>handleActiveTab('photos')}>
                            <img src={state.activeTab === 'photos' ? tabSRCs.photos.active : tabSRCs.photos.inactive}></img>
                            <p style={state.activeTab === 'photos' ? {color: 'rgb(0, 122, 255)'} : {color: 'rgb(152,152,152)'}}>Photos</p>
                        </div>
                    </Link>

                    <Link to='/menu'>
                        <div className='footbutt' onClick={()=>handleActiveTab('back')}>
                            <img src={state.activeTab === 'back' ? tabSRCs.back.active : tabSRCs.back.inactive}></img>
                            <p style={state.activeTab === 'back' ? {color: 'rgb(0, 122, 255)'} : {color: 'rgb(152,152,152)'}}>Back</p>
                        </div>
                    </Link>

                </footer>

            </section>
        );
};

export default IPhoneLayout;