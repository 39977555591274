import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Menu from './components/Menu';
import Styling from './pages/Styling';
import Writing from './pages/Writing';
import About from './pages/About';
import Film from './pages/Film';
import Gallery from './pages/Gallery';
import Booking from './pages/Booking';
import Portfolio from './pages/Portfolio';
import WritingModal from './pages/WritingModal';
import TwitterLogo from './assets/svgicons/icons8-twitter.svg';
import InstaLogo from './assets/svgicons/icons8-instagram.svg';
import DepopLogo from './assets/svgicons/icons8-depop.svg';
import LinkedLogo from './assets/svgicons/icons8-linkedin.svg';
import './App.css';
import { Triangle } from 'three';

class App extends Component {

  state = {
    width: window.innerWidth,
    height: window.innerHeight,
    enterClicked: false,
    firstPlay: false,
    muted: true,
    loadEnd: false
  };

  handleGlobalState = (state, value) => {
    this.setState({ [state]: value })
  }

  updateDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  };

  mutedOrNot = () => {
    if (this.state.muted === true) {
      this.setState({muted: false});
      this.audio1.play();
    } else {
      this.setState({muted: true});
      this.audio1.pause();
    }
  };

  componentDidMount = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
    window.addEventListener('resize', this.updateDimensions);
    this.audio1.loop = true;
  };

  enterSite = () => {
    this.setState({enterClicked: true});
  };

  checkStatusPlayAudio = () => {
    if ((this.state.loadEnd || localStorage.visited) && this.state.firstPlay === false) {
      this.setState({firstPlay: true, muted: false});
      this.audio1.play();
      console.log(document.getElementsByTagName("audio"));
    }
  }

  audios = ['https://cassbucket.s3.us-east-2.amazonaws.com/loop1.mp3', 'https://cassbucket.s3.us-east-2.amazonaws.com/loop2.mp3'];
  audio1 = new Audio('https://cassbucket.s3.us-east-2.amazonaws.com/loop1.mp3');

  AnimatedSwitch = withRouter(({ location }) => (
    <>
    <TransitionGroup>
      <CSSTransition
      key={location.key}
      classNames='fader'
      timeout={1000}
      >
        <Switch location={location}>

          <Route exact path='/'>
            <Menu width={this.state.width} height={this.state.height} cass='casscellina' showLoader={this.state.showLoader} handleGlobalState={this.handleGlobalState} />
          </Route>

          <Route exact path='/menu'>
            <Menu forceAnim={true} width={this.state.width} height={this.state.height} handleGlobalState={this.handleGlobalState} />
          </Route>

          <Route exact path='/about'>
            <About width={this.state.width} height={this.state.height} />
          </Route>

          <Route exact path='/styling'>
            <Styling width={this.state.width} height={this.state.height} />
          </Route>

          {/* <Route exact path='/gallery'>
            <Gallery width={this.state.width} height={this.state.height} />
          </Route> */}

          <Route exact path='/writing'>
            <Writing width={this.state.width} height={this.state.height} />
          </Route>

          {/* <Route exact path='/film'>
            <Film width={this.state.width} height={this.state.height} />
          </Route> */}

          <Route exact path='/booking'>
            <Booking width={this.state.width} height={this.state.height} bookingOption='allApts' />
          </Route>

          <Route exact path='/booking/styling'>
            <Booking width={this.state.width} height={this.state.height} bookingOption='styling' />
          </Route>

          <Route exact path='/booking/writing'>
            <Booking width={this.state.width} height={this.state.height} bookingOption='writing' />
          </Route>

          
          <Route exact path='/booking/photofilm'>
            <Booking width={this.state.width} height={this.state.height} bookingOption='photofilm' />
          </Route>

          {/* <Route exact path='/writing/:selection'>
            <WritingModal width={this.state.width} height={this.state.height} />
          </Route> */}

          <Route path='/portfolio'>
            <Portfolio />
          </Route>

          <Route path='*'>
            <Menu width={this.state.width} height={this.state.height} cass='casscellina' visited={this.state.visited} handleGlobalState={this.handleGlobalState} />
          </Route>

        </Switch>
      </CSSTransition>
    </TransitionGroup>
    </>
  ));

  render() {
    return (
      <Router>
        <div className="App" onClick={()=>this.checkStatusPlayAudio()}>
        {this.state.enterClicked === false && localStorage.visited === undefined ? 
        <section className='preland' style={{ color: 'white', fontSize: '15vmin', cursor: 'pointer'}} onClick={()=>this.enterSite()}>
          enter
        </section>
        :
        <>
          <header className='socialsOverlay'>
          <img src={this.state.muted ? 'https://i.imgur.com/cYifxb7.png' : 'https://i.imgur.com/Mp2XZ6D.png'} onClick={()=>this.mutedOrNot()}/>
          <a href='https://www.twitter.com/dailydoseee' target='_blank' rel='noopener noreferrer'><img src={TwitterLogo}/></a>
          <a href='https://www.instagram.com/casscellina/' target="_blank" rel="noopener noreferrer"><img src={InstaLogo}/></a>
          <a href='https://www.depop.com/crescendos/' target="_blank" rel="noopener noreferrer"><img src={DepopLogo}/></a>
          <a href='https://www.linkedin.com/in/cassi-crededio-195765b5/' target='_blank' rel='noopener noreferrer'><img src={LinkedLogo}/></a>

          </header>
          <this.AnimatedSwitch />
          </>
        }
        </div>
      </Router>
    );
  }
}

export default App;
