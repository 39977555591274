let albums = {
    cher: {
        title: 'Cher',
        data: 
        ['https://i.imgur.com/zJC9kIq.jpg', 'https://i.imgur.com/cFilOtO.jpg', 'https://i.imgur.com/u0hwRvq.jpg', 'https://i.imgur.com/je7zXXG.jpg',
        'https://i.imgur.com/4k6aQpU.jpg', 'https://i.imgur.com/DB1ZczW.jpg', 'https://i.imgur.com/CLeSrKl.jpg', 'https://i.imgur.com/Fzn7rLb.jpg', 
        'https://i.imgur.com/1g0uayJ.jpg', 'https://i.imgur.com/klJIyBS.jpg',
        ]
    },
    notre: {
        title: 'January, 2023 Marine Serre for NOTRE. Brand - Marine Serre, Photo & Edit - Alexandra Pombo @theoxproject, Styling - by Casscellina, MUA - Denise @denisemuaartistry, Model - @_maryamjama @o.g_nana',
        data: 
        ['https://i.imgur.com/5esWYIH.jpg', 'https://i.imgur.com/EAx2nQP.jpg', 'https://i.imgur.com/9OmT7Ts.jpg', 'https://i.imgur.com/FYpHZBd.jpg',
        'https://i.imgur.com/45lk4J3.jpg', 'https://i.imgur.com/wZ55mRT.jpg', 'https://i.imgur.com/eRW87WC.jpg'
        ]
    },
    xingyirunway: {
        title: 'Stella Xìng Yi Runway, DEC 2021 - Sea Creature Collection. Styled by Casscellina, Produced by Jaron Bates',
        data:
        ['https://i.imgur.com/d1g96kK.jpg', 'https://i.imgur.com/RxBfnGX.jpg', 'https://i.imgur.com/m3HMguq.jpg',
        'https://i.imgur.com/RkaLmRt.jpg', 'https://i.imgur.com/p89Ayzv.jpg', 'https://i.imgur.com/Qh4aBa3.jpg', 'https://i.imgur.com/jIZJXdc.jpg',
        'https://i.imgur.com/pKM7Dcg.jpg', 'https://i.imgur.com/XcMGAFY.jpg', 'https://i.imgur.com/0f38gux.jpg', 'https://i.imgur.com/JvuQM2g.jpg',
        'https://i.imgur.com/4BX11tx.jpg',
        'https://i.imgur.com/bH3vFc1.jpg', 'https://i.imgur.com/d3CGrQg.jpg', 'https://i.imgur.com/gq8aFZB.jpg', 'https://i.imgur.com/nbbEMn8.jpg',
        'https://i.imgur.com/c9bEf4I.jpg', 'https://i.imgur.com/rXS742d.jpg', 'https://i.imgur.com/ANJqFIp.jpg', 'https://i.imgur.com/l4ttdFq.jpg', 
        'https://i.imgur.com/Pt5aAxk.jpg', 'https://i.imgur.com/vwMesjh.jpg', 'https://i.imgur.com/4RQ9q1g.jpg', 'https://i.imgur.com/zNwS72d.jpg', 
        'https://i.imgur.com/GtFRYl4.jpg', 'https://i.imgur.com/KsEBJxV.jpg', 'https://i.imgur.com/G71Xhfz.jpg', 'https://i.imgur.com/yIjCWKM.jpg',
        ]
    },
    mobjournal: {
        title: 'Mob Journal, Vol. 18 - Pg. 31-35 + tear sheet. Jiggy @jiggy.queen in Ballerina Jewelers, Jean Paul Gaultier, Stephen Burrows + more',
        data: 
        ['https://i.imgur.com/bBPthtD.jpg', 'https://i.imgur.com/XtrKsT8.jpg', 'https://i.imgur.com/fGfv8ex.jpg', 'https://i.imgur.com/JM0XgsA.jpg',
        'https://i.imgur.com/aG1oaSZ.jpg', 'https://i.imgur.com/m6gZuo3.jpg', 'https://i.imgur.com/lSuGXE4.jpg', 'https://i.imgur.com/BspuggJ.jpg',
        'https://i.imgur.com/cWK5Hxt.jpg', 'https://i.imgur.com/4FzUqe2.jpg', 'https://i.imgur.com/tI9P8jF.jpg', 'https://i.imgur.com/mOzF6vy.jpg',
        'https://i.imgur.com/0nMysT3.jpg', 'https://i.imgur.com/fdBbkkr.jpg', 'https://i.imgur.com/f7MWWWE.jpg', 'https://i.imgur.com/ZupoOUb.jpg',
        'https://i.imgur.com/sITbxZQ.jpg', 'https://i.imgur.com/JTIyRWk.jpg', 'https://i.imgur.com/pt5osDX.jpg', 'https://i.imgur.com/9ttin7D.jpg',
        'https://i.imgur.com/ANmnrHF.jpg', 'https://i.imgur.com/gKXJTxN.jpg', 'https://i.imgur.com/Hgm9rce.jpg'
        ]
    },
    designer: {
        title: 'Designer + tear sheet. Picture by The Ox Project with Cole Krueger @bottomlashes in Alyssa Wright, Christian Dior, Fil Tadic, The North Face, Roberto Cavalli + more',
        data:
        ['https://i.imgur.com/C8fko5B.jpg', 'https://i.imgur.com/swsH4U5.jpg', 'https://i.imgur.com/uWlbkuk.jpg', 'https://i.imgur.com/Jfqs4va.jpg',
        'https://i.imgur.com/23gEGDj.jpg', 'https://i.imgur.com/dvq7kNH.jpg', 'https://i.imgur.com/0xOLitZ.jpg', 'https://i.imgur.com/XGdXEZW.jpg'
        ]
    },
    tiool: {
        title: 'TIOOL, Intentionally Blank sister brand. Picture + Set by Hope Glassel Photography @hmgphoto with Raven Rains @raven.rains in Bebe, Betsey Johnson, Congruent Space, Filles A Papa, Flo Toronto, Türler Jewellery & Watches, Wekafore + more',
        data: 
        ['https://i.imgur.com/m1AV01L.jpg', 'https://i.imgur.com/CIh9Pfs.jpg', 'https://i.imgur.com/xTMU3Q3.jpg', 'https://i.imgur.com/UIkDj4h.jpg',
        'https://i.imgur.com/GYNlYWS.jpg', 'https://i.imgur.com/yC1IfuP.jpg', 'https://i.imgur.com/4HQd0n1.jpg', 'https://i.imgur.com/d7g3VDj.jpg',
        'https://i.imgur.com/m9E8hWj.jpg', 'https://i.imgur.com/32NFspl.jpg', 'https://i.imgur.com/y32E4vm.jpg', 'https://i.imgur.com/0aZmSkw.jpg',
        'https://i.imgur.com/UxXEeTi.jpg'
        ]
    },
    holythrift: {
        title: 'Planet Juniper, Picture by Casscellina with Jooni @joonimoodi in GCDS @gcdswear x Holy Thrift @holythrift',
        data:
        ['https://i.imgur.com/VWt7DtL.jpg', 'https://i.imgur.com/qqBLOUQ.jpg',
        'https://i.imgur.com/5Nv6gTJ.jpg', 'https://i.imgur.com/rANU1a3.jpg', 
        'https://i.imgur.com/UfQNoJ3.jpg', 'https://i.imgur.com/pBQRREL.jpg', 'https://i.imgur.com/eIUiaXQ.jpg'
        ]
    },
    oxfil : {
        title: 'Collection 00 in Fil Tadic, Casting + Styling by Casscellina, Film by Sasha A @filmsloot',
        data:
        ['https://i.imgur.com/wjgZ9XO.jpg', 'https://i.imgur.com/EzFFns9.jpg', 'https://i.imgur.com/ai6SGgr.jpg', 'https://i.imgur.com/GrFQK0r.jpg',
        'https://i.imgur.com/0Ir0QoT.jpg', 'https://i.imgur.com/HbQw9Bl.jpg', 'https://i.imgur.com/75DgH2a.jpg', 'https://i.imgur.com/vB0Zf6H.jpg',
        'https://i.imgur.com/uNfJoBZ.jpg', 'https://i.imgur.com/ld4eMSt.jpg', 'https://i.imgur.com/dH1Beg0.jpg', 'https://i.imgur.com/4MvgLLZ.jpg',
        'https://i.imgur.com/ApQ62zl.jpg', 'https://i.imgur.com/yvloK59.jpg', 'https://i.imgur.com/osTmtSa.jpg', 'https://i.imgur.com/iCgeopy.jpg',
        'https://i.imgur.com/XEBaEuz.jpg', 'https://i.imgur.com/wvLqHcc.jpg', 'https://i.imgur.com/BrlhXZC.jpg', 'https://i.imgur.com/fdXM07q.jpg',
        'https://i.imgur.com/1lQUPEa.jpg', 'https://i.imgur.com/UW9Ht7J.jpg', 'https://i.imgur.com/EbbShYi.jpg', 'https://i.imgur.com/oek9lYL.jpg',
        'https://i.imgur.com/q1drCCC.jpg', 'https://i.imgur.com/R76in5t.jpg', 'https://i.imgur.com/qoAqTL1.jpg', 'https://i.imgur.com/DYL6yvx.jpg',
        'https://i.imgur.com/RluKyrc.jpg', 'https://i.imgur.com/s5DcPPB.jpg', 'https://i.imgur.com/dFDiI8D.jpg', 'https://i.imgur.com/ttpCerm.jpg',
        'https://i.imgur.com/F2v4Rvk.jpg', 'https://i.imgur.com/NGsN4BL.jpg', 'https://i.imgur.com/avosAFV.jpg', 'https://i.imgur.com/8AZ7ThR.jpg',
        'https://i.imgur.com/JlykOAo.jpg', 'https://i.imgur.com/EyaRsfc.jpg', 'https://i.imgur.com/mLUqgHv.jpg'
        ]
    },
    oxfilbts: {
        title: '@fil_tadic x @filmsloot',
        data:
        ['https://i.imgur.com/XEBaEuz.jpg', 'https://i.imgur.com/wvLqHcc.jpg', 'https://i.imgur.com/BrlhXZC.jpg', 'https://i.imgur.com/fdXM07q.jpg',
        'https://i.imgur.com/1lQUPEa.jpg', 'https://i.imgur.com/UW9Ht7J.jpg', 'https://i.imgur.com/EbbShYi.jpg', 'https://i.imgur.com/oek9lYL.jpg',
        'https://i.imgur.com/q1drCCC.jpg', 'https://i.imgur.com/R76in5t.jpg', 'https://i.imgur.com/qoAqTL1.jpg', 'https://i.imgur.com/DYL6yvx.jpg',
        'https://i.imgur.com/RluKyrc.jpg', 'https://i.imgur.com/s5DcPPB.jpg', 'https://i.imgur.com/dFDiI8D.jpg', 'https://i.imgur.com/ttpCerm.jpg',
        'https://i.imgur.com/F2v4Rvk.jpg', 'https://i.imgur.com/NGsN4BL.jpg', 'https://i.imgur.com/avosAFV.jpg', 'https://i.imgur.com/8AZ7ThR.jpg',
        'https://i.imgur.com/JlykOAo.jpg', 'https://i.imgur.com/EyaRsfc.jpg', 'https://i.imgur.com/mLUqgHv.jpg'
        ]
    },
    favorites: {
        title: 'Favorites',
        data: ['https://i.imgur.com/rupz2Mj.jpg', 'https://i.imgur.com/DykvZsx.jpg', 'https://i.imgur.com/q3WBdiS.jpg', 'https://i.imgur.com/4QgnylW.jpg', 
        'https://i.imgur.com/QL6KAzg.jpg', 'https://i.imgur.com/ydzLsXr.jpg', 'https://i.imgur.com/0tPMZiT.jpg', 'https://i.imgur.com/rRwln0N.jpg',
        'https://i.imgur.com/wDFkHwC.jpg', 'https://i.imgur.com/YLG401f.jpg', 'https://i.imgur.com/YpMUsEo.jpg',
        'https://i.imgur.com/mdryrRG.jpg', 'https://i.imgur.com/H1iRMGj.jpg', 
        ]
    },
    congruentspacexrachel: {
        title: 'Congruent Space, with Rachel Moraga @rachelmoraga ',
        data: [
            'https://i.imgur.com/Qeibzfu.jpg', 'https://i.imgur.com/CX5ez85.jpg', 'https://i.imgur.com/D2ssFsI.jpg', 'https://i.imgur.com/HrvLMig.jpg', 
            'https://i.imgur.com/XNze8PL.jpg','https://i.imgur.com/kXVJiyX.jpg', 'https://i.imgur.com/HkiRq3i.jpg', 'https://i.imgur.com/yNRjRkS.jpg', 
            'https://i.imgur.com/g8vYoah.jpg','https://i.imgur.com/86EWqnX.jpg', 'https://i.imgur.com/2zaZlMo.jpg', 'https://i.imgur.com/TiYmWZr.jpg', 
            'https://i.imgur.com/7dPImTq.jpg',
            'https://i.imgur.com/vbXk5lb.jpg', 'https://i.imgur.com/oR0NEp1.jpg', 'https://i.imgur.com/v0MJbma.jpg', 'https://i.imgur.com/U8pDkwJ.jpg',
            'https://i.imgur.com/LTm7xap.jpg', 'https://i.imgur.com/xxGf6iI.jpg', 'https://i.imgur.com/ycf4jrx.jpg', 'https://i.imgur.com/twXDXBN.jpg',
            'https://i.imgur.com/8kpNncE.jpg', 'https://i.imgur.com/sKwpt7f.jpg', 'https://i.imgur.com/ccx0Sdi.jpg', 'https://i.imgur.com/vOfdXkK.jpg',
            'https://i.imgur.com/Muup92M.jpg', 'https://i.imgur.com/9QbNQr1.jpg', 'https://i.imgur.com/gF6XHwH.jpg', 'https://i.imgur.com/FIdYtMq.jpg',
            'https://i.imgur.com/Yr5EZL4.jpg', 'https://i.imgur.com/TPoPLW4.jpg', 'https://i.imgur.com/92gIxdq.jpg', 'https://i.imgur.com/LRpn6sa.jpg',
            'https://i.imgur.com/MdGFklk.jpg', 'https://i.imgur.com/UFtD8WL.jpg', 'https://i.imgur.com/ufgxHnj.jpg', 'https://i.imgur.com/6Nvfc4F.jpg',
            'https://i.imgur.com/FVfLXYI.jpg', 'https://i.imgur.com/F8Tpvn1.jpg', 'https://i.imgur.com/I7E3qT5.jpg',
            'https://i.imgur.com/D2hHlEE.jpg', 'https://i.imgur.com/Qeibzfu.jpg', 'https://i.imgur.com/3eJBTRH.jpg', 'https://i.imgur.com/2gulx2x.jpg',
            'https://i.imgur.com/rDnMw03.jpg', 'https://i.imgur.com/repwWzw.jpg', 'https://i.imgur.com/RXMIlVT.jpg'
        ]
    },
    rachel: {
        title: 'Congruent Space, with Rachel Moraga @rachelmoraga ',
        data: [
            'https://i.imgur.com/pUV5Gp6.jpg', 'https://i.imgur.com/6QinNPc.jpg', 'https://i.imgur.com/I6xj5A3.jpg', 'https://i.imgur.com/SrEaIBb.jpg',
            'https://i.imgur.com/GSTDZzX.jpg', 'https://i.imgur.com/pRb7VTz.jpg','https://i.imgur.com/JOpGqSZ.jpg', 'https://i.imgur.com/8npLd3k.jpg',
            'https://i.imgur.com/IGFh1tr.jpg', 'https://i.imgur.com/HSyZfC4.jpg', 'https://i.imgur.com/NZVcmlL.jpg', 'https://i.imgur.com/5zUPazg.jpg',
            'https://i.imgur.com/cYi2j8C.jpg', 'https://i.imgur.com/kcZ3ZE5.jpg', 'https://i.imgur.com/1LhBKtc.jpg', 'https://i.imgur.com/WkRxytQ.jpg',
            'https://i.imgur.com/CoYBUrY.jpg', 'https://i.imgur.com/QUTu8mq.jpg', 'https://i.imgur.com/KKjYLiz.jpg', 'https://i.imgur.com/nSU1v7Z.jpg',
            'https://i.imgur.com/7X6DCVO.jpg', 'https://i.imgur.com/FzBFf7H.jpg', 'https://i.imgur.com/Ac23t8F.jpg', 'https://i.imgur.com/EEtELFs.jpg',
            'https://i.imgur.com/T5nFCW6.jpg', 'https://i.imgur.com/4w0FcqW.jpg', 'https://i.imgur.com/n6UePcy.jpg', 'https://i.imgur.com/wtWi9lB.jpg',
            'https://i.imgur.com/DPpRSce.jpg', 'https://i.imgur.com/V862GlL.jpg', 'https://i.imgur.com/MwMumZ0.jpg', 'https://i.imgur.com/O8kHOEv.jpg',
            'https://i.imgur.com/HTkM4yg.jpg', 'https://i.imgur.com/mMSKJ3c.jpg', 'https://i.imgur.com/9ZdIefC.jpg', 'https://i.imgur.com/GHzEWLb.jpg',
            'https://i.imgur.com/O2e5Kd3.jpg', 'https://i.imgur.com/CXP4aoy.jpg', 'https://i.imgur.com/e3jDYqm.jpg', 'https://i.imgur.com/bKghE0H.jpg',
            'https://i.imgur.com/Vqq6GOk.jpg', 'https://i.imgur.com/nGyaRhV.jpg', 'https://i.imgur.com/UqTovDh.jpg', 'https://i.imgur.com/Q9MMxIl.jpg',
            'https://i.imgur.com/Cal7tSF.jpg'
        ]
    },
    rachel2: {
        title: 'Congruent Space, with Rachel Moraga @rachelmoraga',
        data: [
            'https://i.imgur.com/1ifpiwV.jpg', 'https://i.imgur.com/a25zei7.jpg', 'https://i.imgur.com/fV4BzJL.jpg', 'https://i.imgur.com/gCuUTXs.jpg',
            'https://i.imgur.com/w8w5SlW.jpg', 'https://i.imgur.com/OsjO9tW.jpg', 'https://i.imgur.com/ed4bVUM.jpg', 'https://i.imgur.com/eg3XjQg.jpg',
            'https://i.imgur.com/6F8X1PR.jpg', 'https://i.imgur.com/w0JjHnU.jpg', 'https://i.imgur.com/gCuUTXs.jpg', 'https://i.imgur.com/R3XAas5.jpg',
            'https://i.imgur.com/WrAMRjm.jpg', 'https://i.imgur.com/bU8RXn2.jpg', 'https://i.imgur.com/ed2n48U.jpg', 'https://i.imgur.com/uhyZ06j.jpg',
            'https://i.imgur.com/1H4xetE.jpg', 'https://i.imgur.com/poxNX55.jpg', 'https://i.imgur.com/TI2zMbm.jpg'
        ]
    },
    idkak: {
        title: 'Ania Kochana, in Chanel',
        data: [
            'https://i.imgur.com/y5LnBaj.jpg', 'https://i.imgur.com/KeoPAIe.jpg', 'https://i.imgur.com/m7kJMcz.jpg',
            'https://i.imgur.com/p19DTks.jpg', 'https://i.imgur.com/3Zd9DPT.jpg'
        ]
    },
    julesa: {
        title: 'Portrait, Picture by Jimmy Kurz @jimmykurz with Jules Anacua',
        data: [
            'https://i.imgur.com/IOHc2Ne.jpg', 'https://i.imgur.com/eUNv0YW.jpg'
        ]
    },
    kcastel: {
        title: 'Like A Virgin, Picture by Jordan Bunch with Castellano @k.castel_',
        data: [
            'https://i.imgur.com/8NOOpSi.jpg', 'https://i.imgur.com/kpxlCAD.jpg', 'https://i.imgur.com/ZVZm7mR.gif', 'https://i.imgur.com/r18cvXG.jpg',
            'https://i.imgur.com/xc3dMZt.jpg', 'https://i.imgur.com/FPRdbPZ.jpg', 'https://i.imgur.com/fEVfZDn.jpg', 'https://i.imgur.com/HH9D7kQ.jpg',
            'https://i.imgur.com/wstJeS9.gif', 'https://i.imgur.com/XU1hJaj.jpg'
        ]
    },
    stevens: {
        title: 'Natural Light, Picture by Kylie Shaw @kyliewshaw with Lorien + Khaila Stevens',
        data: [
            'https://i.imgur.com/KhNknMB.jpg', 'https://i.imgur.com/BzQSnUa.jpg', 'https://i.imgur.com/o7clVlP.jpg', 'https://i.imgur.com/cKLaq9K.jpg',
            'https://i.imgur.com/F4N4pAO.jpg', 'https://i.imgur.com/P517mXm.jpg', 'https://i.imgur.com/y24obXF.jpg'
        ]
    },
    losertina: {
        title: 'Vintage, Picture by MasterMind Fotoo with Loser Tina @losertina in leather chaps and furs',
        data: [
            'https://i.imgur.com/TFcmLgs.jpg', 'https://i.imgur.com/GVLTFLK.jpg', 'https://i.imgur.com/dNwRwET.jpg', 'https://i.imgur.com/2IbYYJB.jpg',
            'https://i.imgur.com/SUzq4U5.jpg', 'https://i.imgur.com/FB4qyI9.jpg', 'https://i.imgur.com/jJCkRHu.jpg', 'https://i.imgur.com/Zo0Z4zB.jpg',
            'https://i.imgur.com/wxenO2i.jpg', 'https://i.imgur.com/ToiiqML.jpg', 'https://i.imgur.com/uI7J0o6.jpg', 'https://i.imgur.com/s1M6sEn.jpg',
            'https://i.imgur.com/n9vnOFr.jpg'
        ]
    },
    mayasdrm: {
        title: '@mayasdreamhouse',
        data: [
            'https://i.imgur.com/N1jPfCh.jpg', 'https://i.imgur.com/ChgFbkf.jpg', 'https://i.imgur.com/GOdHohb.jpg', 'https://i.imgur.com/zWPnkTF.jpg',
            'https://i.imgur.com/0malxhT.gif'
        ]
    },
    russiaancream: {
        title: 'Sasha, Test shots (Pictured by various)',
        data: [
            'https://i.imgur.com/ZIYhECR.jpg', 'https://i.imgur.com/eRPJg6j.jpg', 'https://i.imgur.com/byrqAiu.jpg', 'https://i.imgur.com/AXbr4fc.jpg',
            'https://i.imgur.com/RtKgowW.jpg', 'https://i.imgur.com/ocO1fOG.jpg', 'https://i.imgur.com/NdiTTd4.jpg','https://i.imgur.com/JE1Vgxs.jpg', 
            'https://i.imgur.com/qEejoTI.jpg', 'https://i.imgur.com/WkvJNvF.jpg', 'https://i.imgur.com/eYcqv3P.jpg', 'https://i.imgur.com/ISCRwTx.jpg',
            'https://i.imgur.com/6mr773i.jpg', 'https://i.imgur.com/PTLQrOK.jpg', 'https://i.imgur.com/FC7aYy6.jpg', 'https://i.imgur.com/UcEQ2Mf.jpg',
            'https://i.imgur.com/UGiXF4X.jpg', 'https://i.imgur.com/K0gWIMP.jpg', 'https://i.imgur.com/oGGqoqS.jpg', 'https://i.imgur.com/8owzrcB.jpg'
        ]
    },
    samcarpenter: {
        title: 'Vibe Szn, Sam Carpenter @samcarpenter in jewelry by Vibe Szn @vibe_szn',
        data: [
            'https://i.imgur.com/JMwORzB.jpg', 'https://i.imgur.com/9HXV5YE.jpg', 'https://i.imgur.com/ef8Uj2i.jpg', 'https://i.imgur.com/aqxexM9.jpg',
            'https://i.imgur.com/7zrOI3X.jpg', 'https://i.imgur.com/DkQjBb0.jpg'
        ]
    },
    uhhjean: {
        title: 'Depop ft. @uhh.jean',
        data: [
            'https://i.imgur.com/yiM2rTS.jpg', 'https://i.imgur.com/PMNEJJh.jpg', 'https://i.imgur.com/cHhSx5I.jpg', 'https://i.imgur.com/vb3roT6.jpg',
            'https://i.imgur.com/K8Hsyto.jpg', 'https://i.imgur.com/UccAnBa.jpg', 'https://i.imgur.com/H0fZwRZ.jpg', 'https://i.imgur.com/SSJwG7f.jpg',
            'https://i.imgur.com/Oac1x9M.jpg', 'https://i.imgur.com/f84wlTa.jpg', 'https://i.imgur.com/WVOrQEB.jpg', 'https://i.imgur.com/4T679fG.jpg',
            'https://i.imgur.com/3QTHj6r.jpg', 'https://i.imgur.com/waUR0Io.jpg', 'https://i.imgur.com/i9ozP3H.jpg', 'https://i.imgur.com/NpnPnyM.jpg',
            'https://i.imgur.com/fDhmeMN.jpg', 'https://i.imgur.com/6fQqeuv.jpg', 'https://i.imgur.com/tOe2Dzr.jpg', 'https://i.imgur.com/odcejSC.jpg', 
            'https://i.imgur.com/xdCxABP.jpg', 'https://i.imgur.com/Oy3SaNZ.jpg', 'https://i.imgur.com/dXzVkub.jpg', 'https://i.imgur.com/xEcMiqU.jpg',
            'https://i.imgur.com/bGsjdcJ.jpg'
        ]
    },
    montegobay: {
        title: 'Frankie Carrera - Montego Bay',
        data: "https://www.youtube.com/embed/L3xI_aLn_Yw?autoplay=1"
    },
    theatrics: {
        title: 'Noah Sims - Theatrics',
        data: "https://www.youtube.com/embed/GhEUbnfXIqU?autoplay=1"
    },
    brainpuddle: {
        title: 'Kupid - Brain Puddle',
        data: 'https://www.youtube.com/embed/50_THKkS_us?autoplay=1'
    },
    vibemaya: {
        title: 'Vibe Szn',
        data: 'https://www.youtube.com/embed/cRUmrlFVmnA?autoplay=1'
    },
    montegobts: {
        title: 'Montego Bay Outfit Special',
        data: 'https://www.youtube.com/embed/MovrIpvxnEE?autoplay=1'
    }
};

export default albums;