import React, { Component } from 'react';
import './sauce.css';

class CuboidSaucer extends Component {

  state = {
      x: 0,
      y: 0
  };

  updateMousePos = (e) => {
    this.setState({x: e.pageX, y: e.pageY});
  };
  
  componentDidMount = () => {
      if (!this.props.smol) {
        window.addEventListener('mousemove', this.updateMousePos)
      } else {
          return;
      }
  };
  componentWillUnmount = () => {
      window.removeEventListener('mousemove', this.updateMousePos);
  };

render() {
return (
    <div className={`saucerPlane`} style={
    {left: `${this.state.x + 50}px`, top: `${this.state.y - 100}px`}
    }
    onClick={this.props.toggleSideNav} title='MENU'>
        <div className={`sCuboid sBody`}>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className={`sCuboid sToppuh`} >
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
            </div>
        </div>
        <div className={`sCuboid sBody`}>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className={`sCuboid sToppuh`} >
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
            </div>
        </div>
        <div className={`sCuboid sBody`}>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className={`sCuboid sToppuh`} >
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
            </div>
        </div>
        <div className={`sCuboid sBody`}>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className={`sCuboid sToppuh`} >
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
            </div>
        </div>
        <div className={`sCuboid sBody`}>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className={`sCuboid sToppuh`} >
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
            </div>
        </div>
        <div className={`sCuboid sBody`}>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className='sCuboid__side'></div>
            <div className={`sCuboid sToppuh`} >
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
                <div className='sCuboid__side'></div>
            </div>
        </div>

    </div>
);
}
}

export default CuboidSaucer;