import React, { Component} from 'react';
import ImgSpotlite from '../ImgSpotlite';
import albums from '../../imgurlinks';
import '../ViewAlbum/viewalbum.css';

class ViewAll extends Component {

    state = {
        spotlite: '', 
        all: []
    };

    handleParentState = (state, value) => {
        this.setState({ [state]: value })
    };

    handleSpotlite = (pusher) => {
        this.setState({spotlite: pusher});
    };

    getAll = () => {
        let all = [];
        for (const property in albums) {
            albums[property].data.forEach(element => {
                all.push(element);
            });
        };
        this.setState({ all: all });
    };

    componentDidMount() {
        this.getAll();
    };

    render () {
    return ( 
        
        <section className='albumswrap'>

        <nav className='navback' style={{color: this.props.color}}>
            {this.state.spotlite !== '' ?
            <i className="fa fa-chevron-left" onClick={()=> this.setState({spotlite: ''})}></i>
            :
            <></>
            }
        </nav>

        {this.state.spotlite === '' ?
        <div className='collection'>
            
            {this.state.all.map((element, i) => {
                return <div key={i}
                    onClick={()=>this.handleSpotlite(element)}
                    style={{background: `url('${element}')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}
                    ></div>
                })
            }
            
        </div>
        :
        <ImgSpotlite handleParentState={this.handleParentState} imgsrc={this.state.spotlite} images={this.state.all} activeSlideKey={this.state.all.indexOf(this.state.spotlite)} />
        }


    </section>
    );
    };
};

export default ViewAll;