import React, { useEffect, useState } from 'react';
import './3d.css';
import * as THREE from 'three';
import RadioSpace from '../../fonts/Radio_Space_Regular.json';
import { useHistory } from 'react-router';
const mobilevideo = 'https://cassbucket.s3.us-east-2.amazonaws.com/mobile_final02.mp4';
const tabletvideo = 'https://cassbucket.s3.us-east-2.amazonaws.com/tablet_final02.mp4';

function ThreeDee(props) {

    const history = useHistory();

    const [state, setState] = useState({vidEnded: false});

    const loadThreeDee = () => {
        var scene = new THREE.Scene();
            var camera = new THREE.PerspectiveCamera( 75, props.width/props.height, 0.1, 1000 );
            var renderer = new THREE.WebGLRenderer({canvas: document.getElementById('canva'), alpha: true});
            renderer.setClearColor( 0x000000, 0 );
            renderer.setSize( props.width, props.height );

            const mouse = new THREE.Vector2();
            const raycaster = new THREE.Raycaster();

            const onMouseMove = (event) => {
                mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
                mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
            };

            let displayBool = getComputedStyle(document.querySelector('.threedwrap'));

            
            const onClick = (e) => {

                if (displayBool.display === 'block') {
                    raycaster.setFromCamera(mouse, camera);
                    let intersects = raycaster.intersectObjects(scene.children, true);

                    if (intersects.length > 0 && intersects[0].object.name.length > 2) {
                        history.push(`/${intersects[0].object.name}`);

                    };
                };
            };

            const updateDimensions = () => {
                renderer.setSize( window.innerWidth, window.innerHeight );
            };


            let canv = document.getElementById('canva');
            window.addEventListener('resize', updateDimensions);
            canv.addEventListener('click',(e) => onClick(e) );
            window.addEventListener('mousemove', (e) => onMouseMove(e));
            
            

            let font = new THREE.FontLoader().parse(RadioSpace);
            var geometry = new THREE.TextGeometry('about', {
                font: font,
                size: 8,
                height: 5,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 2,
                bevelSize: 1,
                bevelOffset: 0,
                bevelSegments: 5
            });
            var geometry2 = new THREE.TextGeometry('portfolio', {
                font: font,
                size: 8,
                height: 5,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 2,
                bevelSize: 1,
                bevelOffset: 0,
                bevelSegments: 5
            });
            var geometry3 = new THREE.TextGeometry('styling', {
                font: font,
                size: 8,
                height: 5,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 2,
                bevelSize: 1,
                bevelOffset: 0,
                bevelSegments: 5
            });
            var geometry4 = new THREE.TextGeometry('booking', {
                font: font,
                size: 8,
                height: 2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 2,
                bevelSize: 1,
                bevelOffset: 0,
                bevelSegments: 5
            });
            var geometry5 = new THREE.TextGeometry('casscellina.com', {
                font: font,
                size: 5.5,
                height: 0.5,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 2,
                bevelSize: 1,
                bevelOffset: 0,
                bevelSegments: 5
            });
            var material = new THREE.MeshPhongMaterial();
            material.color.setHex(0x9cffff);
            material.shininess = 20;
            var cube = new THREE.Mesh( geometry, material );
            let cube2 = new THREE.Mesh( geometry2, material);
            let cube3 = new THREE.Mesh(geometry3,material);
            let cube4 = new THREE.Mesh(geometry4,material);
            let cube5 = new THREE.Mesh(geometry5,material);
            cube.name = 'about';
            cube2.name = 'portfolio';
            //change to portfolio
            cube3.name = 'styling';
            cube4.name = 'booking';
            scene.add( cube, cube2, cube3, cube4, cube5 );
            cube.rotation.z = 0.1;
            cube.rotation.y = 0.25;
            cube.rotation.x = 0.25;
            cube.position.x = 10;
            cube2.position.x = 0;
            cube3.position.x = 5;
            cube4.position.x = -5;
            cube2.position.y = -25;
            cube3.position.y = -50;
            cube4.position.y = -75;
            cube4.position.x = 0;
            cube5.position.y = -100;
            cube5.position.x = -5;
            cube5.rotation.y = -0.05;
            cube5.rotation.x = -0.45;
            var light = new THREE.DirectionalLight( 0xffffff );
            light.position.set( 0, 0, 1).normalize();
            scene.add(light);
            camera.position.x = 25;
            camera.position.y = -45;
            camera.position.z = 100;
            let itt = 0;
            var animate = function () {
                if (itt >= 2000) {
                    itt = 0;
                    requestAnimationFrame( animate );
                    renderer.render( scene, camera );
                } else if (itt < 500) {
                    itt+=1;
                    cube.rotation.y += 0.001;
                    cube.rotation.x += 0.001;
                    cube2.rotation.y -= 0.0005;
                    cube2.rotation.x -= 0.0005;
                    cube3.rotation.y += 0.001;
                    cube3.rotation.x += 0.001;
                    cube4.rotation.y -= 0.0005;
                    cube4.rotation.x -= 0.0005;
                    cube.position.x -= 0.001;                
                    cube3.position.x -= 0.001;
                    camera.position.y -= 0.01;
                    requestAnimationFrame( animate );
                    renderer.render( scene, camera );
                } else if (itt >= 1500) {
                    itt+=1;
                    cube.rotation.y += 0.0005;
                    cube.rotation.x += 0.0005;
                    cube2.rotation.y -= 0.001;
                    cube2.rotation.x -= 0.001;
                    cube3.rotation.y += 0.0005;
                    cube3.rotation.x += 0.0005;
                    cube4.rotation.y -= 0.001;
                    cube4.rotation.x -= 0.001;
                    cube.position.x += 0.0005;                
                    cube3.position.x += 0.0005;
                    camera.position.y += 0.01;
                    requestAnimationFrame( animate );
                    renderer.render( scene, camera );
                } else if (itt >= 1000) {
                    itt+=1;
                    cube.rotation.y -= 0.0005;
                    cube.rotation.x -= 0.0005;
                    cube2.rotation.y += 0.001;
                    cube2.rotation.x += 0.001;
                    cube3.rotation.y -= 0.0005;
                    cube3.rotation.x -= 0.0005;
                    cube4.rotation.y += 0.001;
                    cube4.rotation.x += 0.001;
                    cube.position.x += 0.0005;               
                    cube3.position.x += 0.0005;
                    camera.position.y -= 0.01;
                    requestAnimationFrame( animate );
                    renderer.render( scene, camera );
                }  else if (itt >= 500) {
                    itt+=1;
                    cube.rotation.y -= 0.001;
                    cube.rotation.x -= 0.001;
                    cube2.rotation.y += 0.0005;
                    cube2.rotation.x += 0.0005;
                    cube3.rotation.y -= 0.001;
                    cube3.rotation.x -= 0.001;
                    cube4.rotation.y += 0.0005;
                    cube4.rotation.x += 0.0005;
                    cube.position.x -= 0.001;              
                    cube3.position.x -= 0.001;
                    camera.position.y += 0.01;
                    requestAnimationFrame( animate );
                    renderer.render( scene, camera );
                }
            };
            animate();

            return () => {
                window.removeEventListener('mousemove', (e) => onMouseMove(e));
                window.removeEventListener('resize', updateDimensions);
                scene.clear();
            };
    };

    const handleVidEnd = () => {
        setState({vidEnded: true});
        props.handleVidEnd2();
    };

    useEffect(() => {
        loadThreeDee();
    }, []);

    return (
        <section className='threedwrap'>
            <canvas id='canva'></canvas>
            <div className={props.showLoader === false || state.vidEnded ? 'hideAnim' : 'animLoadScreen'}>
                <video src={props.height > props.width && props.height < 768 ? mobilevideo : tabletvideo} playsInline autoPlay muted onEnded={handleVidEnd}></video>
            </div>
        </section>
    );
};

export default ThreeDee;