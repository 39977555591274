import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

class About extends Component {

    state = {
        scrollMultiplier: 1,
        lastScrollTop: 0,
        cherub: 'https://i.imgur.com/cETo9iD.png'
    }

    handleScroll = (e) => {
        let newScrollTop = e.target.scrollTop;
        if ( newScrollTop > this.state.lastScrollTop) {
            this.setState({scrollMultiplier: this.state.scrollMultiplier+=3});
        } else {
            this.setState({scrollMultiplier: this.state.scrollMultiplier-=3});
        };
        this.setState({lastScrollTop: newScrollTop});
    };

    scrollBot = () => {
        document.querySelector('.aboutWrapper').scrollTo({
            top: 999999,
            left: 0,
            behavior: 'smooth'
        });
    };

    componentDidMount = () => {
    };

    componentWillUnmount() {
        window.removeEventListener('resize', {});
    };

    render() {
        return (
            <section className='aboutWrapper' onScroll={(e) => this.handleScroll(e)}>

                <div className='topBg'>
                    <h1>casscellina</h1>
                    <img src='https://i.imgur.com/TvVIc0R.jpg'></img>
                </div>

                <div className='clouds' onClick={this.scrollBot}>
                    <img className='cloud' style={{right: `${-1*this.state.scrollMultiplier}px`}} src='https://i.imgur.com/O00N2rC.png'></img>
                    <img className='cloud' style={{left: `${-2*this.state.scrollMultiplier}px`}} src='https://i.imgur.com/QsEXyqC.png'></img>
                    <img className='cloud' style={{left: `${1*this.state.scrollMultiplier}px`}} src='https://i.imgur.com/tvQ7Dbu.png'></img>
                    <img className='cloud' style={{right: `${2*this.state.scrollMultiplier}px`}} src='https://i.imgur.com/ISsxtyt.png'></img>
                </div>


                <div className='bio'>

                    <div>
                        <p>
                        <span>Multidisciplinary artist- Writing, Styling and Creative Direction.</span>
                        <br/>
                        <br />
                        <Link to='/portfolio'><span>Works</span></Link> vary from book deals to five hundred plus attendee runway shows. Casscellina styles and directs videos for the Arts, produces promotional, lifestyle, and brand focused content, creates scripts, pilots, treatments, movie bibles; essentially regularly planning/managing large production budgets. 
                        <br />
                        <br />
                        Recently, she’s <strong>pitching</strong> two parts of the three part Crime Fiction family saga <em>No Questions Asked: A Series of Syndicate Crimes</em>⸺ a 170,000 word cross-genre novel with television adaptations on the horizon.
                        </p>
                        <p><a href="mailto:nqawriter@gmail.com"><strong>nqawriter@gmail.com</strong></a></p>
                        <p><a href="mailto:credediocassi@gmail.com">credediocassi@gmail.com</a></p>
                    </div>
                
                </div>

                <div className='contactLinks'>
                    <a href='https://www.instagram.com/casscellina/' target="_blank" rel="noopener noreferrer"><img src='https://i.imgur.com/sf3xkj1.png'></img></a>
                    <a href='https://www.depop.com/crescendos/' target="_blank" rel="noopener noreferrer"><img src='https://i.imgur.com/khe8EDF.png'></img></a>
                    <a href='https://www.twitter.com/dailydoseee' target='_blank' rel='noopener noreferrer'><img src='https://i.imgur.com/5dUI9JS.jpg'></img></a>
                </div>

                <div className='cherubs'>
                    <img className='rub' src='https://i.imgur.com/HE5hiSf.png'></img>
                    <Link to='/menu'>
                        <img className='rub' src='https://i.imgur.com/cETo9iD.png'></img>
                    </Link>
                </div>

                <div className='menuBanner'>
                </div>

                <div className='menuBanner menuBanner2'>
                    <Link to='/'>home</Link>
                    <Link to='/portfolio'>portfolio</Link>
                    <Link to='/styling'>styling</Link>
                    <Link to='/booking'>book now</Link>
                </div>

            </section>
        )
    };
};

export default About;