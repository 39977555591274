import React, { Component } from 'react';
import '../App.css';
import CuboidDresser from '../components/CuboidDresser';
import { PopupText } from 'react-calendly';
import { Link } from 'react-router-dom';

class Styling extends Component {

    state = {
        active: 'inquire',
        activeP: <><p>Click a {this.props.width >= 1000 ? 'drawer' : 'tab'} to view Style services.</p><p><PopupText text='Consult' url='https://calendly.com/casscellina/style-consultation' styles={{color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}/> - <a href='mailto:credediocassi@gmail.com' style={{textDecoration: 'underline', color: 'purple'}}>credediocassi@gmail.com</a></p></>,
        activeLink: 'op4'
    };

    styledescriptions = {
        wardrobestylist: (<>
        <p>$75/hr. ~ <PopupText text='Consult' url='https://calendly.com/casscellina/style-consultation' styles={{color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}/> required.</p>
        <p>Expertly crafted outfits <em>by Casscellina</em>.</p>
        <p>
            <b>
            <PopupText text='Half' url='https://calendly.com/casscellina/style' styles={{color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}/>
                /
            <PopupText text='Full' url='https://calendly.com/casscellina/stylist-1' styles={{color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}/> Day Blocks are both available.
            </b>
        </p>
        <p style={{fontSize: '2vmin'}}>Hair, MUA, Photographer, Space, Set Design creative direction/production rates <PopupText text='here' url='https://calendly.com/casscellina/8-14' styles={{color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}/> </p>
        </>
        ),
        personalshopper: (<>
            <p>$250 Minimum ~ <PopupText text='Book Now' url='https://calendly.com/casscellina/personal-shopper' styles={{color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}/></p>
            <p>Shop everyday wear, for a special occasion or an extended vacation, etc. with Casscellina.</p>
            <p>Applicable to all sizes. Surprise bundles available.</p>
        </>
        ),
        closetcleanouts: (<>
        <p>$60/hr. ~ <PopupText text='Book Now' url='https://calendly.com/casscellina/closet-cleanout' styles={{color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}/></p>
        <p>Sort, delete + shift your wardrobe.</p>
        <p>Available for travel outside the greater Chicagoland area upon request.</p>
        </>),
        inquire: (<>
        <p>Click a {this.props.width >= 1000 ? 'drawer' : 'tab'} to view Style services.</p>
        <p><PopupText text='Consult' url='https://calendly.com/casscellina/style-consultation' styles={{color: 'rgb(0, 0, 255)', textDecoration: 'underline' }}/> - <a href='mailto:credediocassi@gmail.com' style={{textDecoration: 'underline', color: 'purple'}}>credediocassi@gmail.com</a></p>
        </>
        )
    };

    setActiveDrawer = (titleSet, linkSet) => {
        this.setState({
            active: titleSet, 
            activeP: this.styledescriptions[titleSet.replace(/\s/g, "")],
            activeLink: linkSet
        });
    };
    

    render() {
        return (
            <section className='stylingWrapper'>
                <div className='dressholder'>
                    <CuboidDresser setActiveDrawer={this.setActiveDrawer} />
                </div>

                <div className='styleHeader'>
                    <ul className='styleOptions'>
                        <li onClick={()=>this.setActiveDrawer('inquire', 'op4')} style={this.state.activeLink === 'op4' ? {background: 'radial-gradient(red, orange, yellow, green, blue, indigo, violet)', color: 'white'} : {}}>inquire</li>
                        <li onClick={()=>this.setActiveDrawer('closet cleanouts', 'op3')} style={this.state.activeLink === 'op3' ? {background: 'radial-gradient(red, orange, yellow, green, blue, indigo, violet)', color: 'white'} : {}}>closet cleanouts</li>
                        <li onClick={()=>this.setActiveDrawer('personal shopper', 'op2')} style={this.state.activeLink === 'op2' ? {background: 'radial-gradient(red, orange, yellow, green, blue, indigo, violet)', color: 'white'} : {}}>personal shopper</li>
                        <li onClick={()=>this.setActiveDrawer('wardrobe stylist', 'op1')} style={this.state.activeLink === 'op1' ? {background: 'radial-gradient(red, orange, yellow, green, blue, indigo, violet)', color: 'white'} : {}}>wardrobe stylist</li>
                    </ul>
                </div>

                <aside>
                    <h1>{this.state.active}</h1>
                    <div>{this.state.activeP}</div>
                </aside>
                
                <Link to='/menu'>
                    <img src='https://i.imgur.com/p9p7Bs5.png'></img>
                </Link>
                
                <img src='https://cdn.shopify.com/s/files/1/1506/6562/products/PC021_Large_3.jpg?v=1580512672' alt='woodFloor'></img>
                
            </section>
            
        )
    }
};

export default Styling;