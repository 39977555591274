import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import './imgs.css';
import { useSwipeable } from 'react-swipeable';
import SwiperCore, {
    Navigation
  } from 'swiper';

function ImgSpotlite(props) {

    SwiperCore.use([Navigation]);

    const [state, setState] = useState({swiping: 1});

    const [windowDimensions, setWindowDimensions] = useState({width: window.innerWidth, height: window.innerHeight});

    const params = {
        navigation: {
            nextEl: windowDimensions.width < windowDimensions.height ? '' : '.swiper-button-next',
            prevEl: windowDimensions.width < windowDimensions.height ? '' : '.swiper-button-prev'
        },
        spaceBetween: 30
    };

    const config = {
        delta: 100,
        preventDefaultTouchmoveEvent: false,
        trackTouch: true,
        trackMouse: false,
        rotationAngle: 0
    };

    const handlers = useSwipeable({
        onSwipedUp: () => setState({swiping: 1}),
        onSwipedDown: () => { 
            setState({swiping: 1});
            props.handleParentState('spotlite', '');
        },
        onSwiping: (event) => { 
            console.log(props.imgsrc);
            if (event.dir === 'Down' && !(state.swiping <= 0.75)) {
                setState({swiping: state.swiping-=0.001});
            }
            if (event.dir === 'Up' && !(state.swiping >= 1)) {
                setState({swiping: state.swiping+=0.001});
            }
        },
        ...config
    });
    
    const updateDimensions = () => {
        setWindowDimensions({width: window.innerWidth, height: window.innerHeight});
        console.log(windowDimensions.width, windowDimensions.height);
    };
    
    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <section className='spotlite' >
            <Swiper
            {...params}
            {...handlers}
            activeSlideKey={props.activeSlideKey.toString()}
            >
                {props.images.map((element, i) => {
                return <div key={i}>
                <img 
                src={element} style={{transform: `scale(${state.swiping}) translateY(${state.swiping === 1 ? state.swiping : state.swiping*10}vh)`}}></img>
                </div>
                })}

            </Swiper>
        </section>
    );
};

export default ImgSpotlite;