import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './clapper.css';

function CuboidClapper(props) {


  const handleAnimate = () => {


    if (!props.mini) { 
      setHov(true);
      props.handleMenuState('animate', true);
    } else {
      window.scrollTo(0,0);
    }
  };

  const [hovered, setHov] = useState(false);

  const handleMouseOver = () => {
    if (!props.animate) {
      setHov(true);
    }
  };

  const handleMouseOut = () => {
    setHov(false);
  };

  return (
    <Link to='/menu'>
      <div 
      className={`planemaybe animate ${props.mini ? 'smallMenu' : ''}`}
      onMouseOver={()=>handleMouseOver()}
      onMouseLeave={()=>handleMouseOut()}
      onClick={()=>handleAnimate()}>
        <div className={`cuboid ${props.width<500? 'smlCuboid' : ''}`} style={hovered ? {transform: 'rotate(-10deg) translateY(-4vmin) translateX(-0.5vmin)'} : {}}>
            <div className='cuboid__side'></div>
            <div className='cuboid__side'></div>
            <div className='cuboid__side'></div>
            <div className='cuboid__side'></div>
            <div className='cuboid__side'></div>
            <div className='cuboid__side'></div>
        </div>
        <div className={`cuboid ${props.width<500? 'smlCuboid' : ''}`}>
            <div className='cuboid__side'>
              <div className='clapTops'>
                <span>scene<br></br><span>420</span></span>
                <span>take<br></br><span>69</span></span>
                <span>roll<br></br><span>27</span></span>
              </div>
              <p>{props.activeHover}</p>
            </div>
            <div className='cuboid__side'>
              <div className='clapTops'>
                <span>scene<br></br><span>1</span></span>
                <span>take<br></br><span>333</span></span>
                <span>roll<br></br><span>27</span></span>
              </div>
              <p>{props.activeHover}</p>
            </div>
            <div className='cuboid__side'></div>
            <div className='cuboid__side'></div>
            <div className='cuboid__side'></div>
            <div className='cuboid__side'></div>
        </div>
        </div>
    </Link>
  );
}

export default CuboidClapper;