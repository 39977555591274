import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './albums.css';

class Albums extends Component {

    photos =
    <>
    <div className='itemwrap'>
        <Link to='/portfolio/photos/cher'>
            <div style={{background: `url('https://i.imgur.com/zJC9kIq.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>Cher</p>
        <p></p>
    </div>
    <div className='itemwrap'>
        <Link to='/portfolio/photos/notre'>
            <div style={{background: `url('https://i.imgur.com/5esWYIH.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>January, 2023 Marine Serre for NOTRE</p>
        <p>Brand - Marine Serre, Photo & Edit - Alexandra Pombo @theoxproject, Styling - by Casscellina, MUA - Denise @denisemuaartistry , Model - @_maryamjama @o.g_nana</p>
    </div>
    <div className='itemwrap'>
        <Link to='/portfolio/photos/xingyirunway'>
            <div style={{background: `url('https://imgur.com/d1g96kK.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>Stella Xìng Yi Runway, DEC 2021</p>
        <p>Sea Creature Collection. Styled by Casscellina, Produced by Jaron Bates.</p>
    </div>
    <div className='itemwrap'>
        <Link to='/portfolio/photos/mobjournal'>
            <div style={{background: `url('https://i.imgur.com/bBPthtD.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>Magazine Publication</p>
        <p>Mob Journal, Vol. 18: Pages 31-35 + Tear Sheet by the Ox Project. Jiggy @jiggy.queen styling by Casscellina in Ballerina Jewelers, Jean Paul Gaultier, Stephen Burrows, etc.</p>
    </div>
    <div className='itemwrap'>
        <Link to='/portfolio/photos/designer'>
            <div style={{background: `url('https://i.imgur.com/swsH4U5.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>Editorial</p>
        <p>Pictures + Tear Sheet by The Ox Project. Cole Krueger @bottomlashes styling by Casscellina in Alyssa Wright, The North Face, Roberto Cavalli + Deadstock Vintage.</p>
    </div>
    <div className='itemwrap'>
        <Link to='/portfolio/photos/tiool'>
            <div style={{background: `url('https://i.imgur.com/GYNlYWS.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>Brand Work</p>
        <p>TIOOL - Intentionally Blank sister company. Pictures + Set by Hope Glassel Photography @hmgphoto. Raven Rains @raven.rains styling by Casscellina in Betsey Johnson, Filles A Papa, This Is Our Other Line, Türler Jewellery & Watches, Wekafore, etc.</p>
    </div>
    <div className='itemwrap'>
        <Link to='/portfolio/photos/oxfil'>
            <div style={{background: `url('https://i.imgur.com/wjgZ9XO.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>Designer Lookbook</p>
        <p>Collection 00 by Fil Tadic. Casting + Styling by Casscellina. Film by Sasha A. @filmsloot</p>
    </div>
    <div className='itemwrap'>
        <Link to='/portfolio/photos/samcarpenter'>
            <div style={{background: `url('https://i.imgur.com/DkQjBb0.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>Social Media</p>
        <p>Vibe Szn promotional shoot. Sam Carpenter @samcarpenter styling by Casscellina in jewelry by Vibe Szn @vibe_szn</p>
    </div>
    <div className='itemwrap'>
        <Link to='/portfolio/photos/rachel2'>
            <div style={{background: `url('https://i.imgur.com/w0JjHnU.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>Congruent Space</p>
        <p>with Rachel Moraga @rachelmoraga</p>
    </div>
    <div className='itemwrap'>
        <Link to='/portfolio/photos/losertina'>
            <div style={{background: `url('https://i.imgur.com/ToiiqML.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>Multimedia</p>
        <p>Pictures + Edit by MasterMind Fotoo. Loser Tina @losertina styling by in leather chaps + fur available to rent</p>
    </div>
    {/* <div className='itemwrap'>
        <Link to='/portfolio/photos/holythrift'>
            <div style={{background: `url('https://i.imgur.com/qqBLOUQ.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '-75px 0'}}>
            </div>
        </Link>
        <p>"Planet Juniper"</p>
        <p>By Casscellina. Jooni @joonimoodi in GCDS @gcdswear x Holy Thrift @holythrift accessories</p>
    </div>
    <div className='itemwrap'>
        <Link to='/portfolio/photos/julesa'>
            <div style={{background: `url('https://i.imgur.com/IOHc2Ne.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>Portrait</p>
        <p>Picture by Jimmy Kurz @jimmykurz with Jules Anacua</p>
    </div> */}
    <div className='itemwrap'>
        <Link to='/portfolio/photos/russiaancream'>
            <div style={{background: `url('https://i.imgur.com/AXbr4fc.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>Test Shots</p>
        <p>Sasha styling by Casscellina. Pictures by various</p>
    </div>
    <div className='itemwrap'>
        <Link to='/portfolio/photos/kcastel'>
            <div style={{background: `url('https://i.imgur.com/wstJeS9.gif')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>"Like a Virgin"</p>
        <p>Pictures by Jordan Bunch. GIF by Casscellina. Castellano @k.castel_ styling by Casscellina in Christian Dior and petticoat.</p>
    </div>
    <div className='itemwrap'>
        <Link to='/portfolio/photos/idkak'>
            <div style={{background: `url('https://i.imgur.com/m7kJMcz.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>Ania Kochana</p>
        <p>in Chanel</p>
    </div>
    {/* <div className='itemwrap'>
        <Link to='/portfolio/photos/uhhjean'>
            <div style={{background: `url('https://i.imgur.com/yiM2rTS.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>Depop ft. @uhh.jean</p>
        <p>25</p>
    </div> */}
    <div className='itemwrap'>
        <Link to='/portfolio/photos/stevens'>
            <div style={{background: `url('https://i.imgur.com/cKLaq9K.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>Exploring Light</p>
        <p>Picture by Kylie Shaw @kyliewshaw with Lorien + Khaila Stevens</p>
    </div>
    {/* <div className='itemwrap'>
        <Link to='/portfolio/photos/mayasdrm'>
            <div style={{background: `url('https://i.imgur.com/N1jPfCh.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            </div>
        </Link>
        <p>@mayasdreamhouse</p>
        <p>5</p>
    </div>
    <div className='itemwrap'>
        <Link to='/portfolio/photos/rachel'>
            <div style={{background: "url('https://i.imgur.com/pUV5Gp6.jpg')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '0 35%'}}>
            </div>
        </Link>
        <p>@rachelmoraga</p>
        <p>45</p>
    </div> */}
    </>;

    videos = 
    <>
    <div className='itemwrap vid'>
        <Link to='/portfolio/videos/theatrics'>
            <div style={{background: "url('https://img.youtube.com/vi/GhEUbnfXIqU/sddefault.jpg')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 0%'}}>
            </div>
        </Link>
        <p>Noah Sims - Theatrics</p>
        <p>Styling</p>
    </div>
    <div className='itemwrap vid'>
        <Link to='/portfolio/videos/brainpuddle'>
            <div style={{background: "url('https://i.imgur.com/EvZXVKh.png')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 0%'}}>
            </div>
        </Link>
        <p>Kupid - Brain Puddle</p>
        <p>Director + Producer (Unreleased)</p>
    </div>
    {/* <div className='itemwrap vid'>
        <Link to='/portfolio/videos/montegobay'>
            <div  style={{background: "url('https://i.imgur.com/HqRZtR8.jpg')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 0%'}}>
            </div>
        </Link>
        <p>Frankie Carrera - Montego Bay</p>
        <p>Styling + Casting</p>
    </div> */}
    {/* <div className='itemwrap vid'>
        <Link to='/portfolio/videos/montegobts'>
            <div style={{background: "url('http://i3.ytimg.com/vi/MovrIpvxnEE/hqdefault.jpg')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 0%'}}>
            </div>
        </Link>
        <p>Montego Bay Outtake</p>
        <p>BTS</p>
    </div> */}
    {/* <div className='itemwrap vid'>
        <Link to='/portfolio/videos/vibemaya'>
            <div style={{background: "url('http://i3.ytimg.com/vi/cRUmrlFVmnA/hqdefault.jpg')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 0%'}}>
            </div>
        </Link>
        <p>Vibe Szn BTS</p>
        <p>BTS</p>
    </div> */}
    </>;

    writing = 
    <>
    <div className='itemwrap'>
        <p>Producing</p>
        <p>27</p>
    </div>
    <div className='itemwrap'>
        <p>No Questions Asked</p>
        <p>1111</p>
    </div>
    <div className='itemwrap'>
        <p>Part I + II coming soon...</p>
        <p>333</p>
    </div>
    <div className='itemwrap'>
    </div>
    </>;

    render () {
    return (
        <section className='albumswrap'>

            <h1>{this.props.which}</h1>

            <div className='inner'>
                <div className='head' >
                    <h3>
                        My Albums
                    </h3>
                </div>

                <div className='sidescroller'>

                    {this[(this.props.which).toLowerCase()]}
                    
                </div>
            </div>


        </section>
    );
    };
};

export default Albums;