import React, {useState, useEffect} from 'react';
import './dresser.css';

function CuboidDresser(props) {

const [mouseX, setX] = useState({x:30});
const [mouseY, setY] = useState({y:-25});
const updateMousePos = (e) => {
    setX({x: -e.clientX/100});
    setY({y: -e.clientY/100});
}
useEffect(() => {
    window.addEventListener("mousemove", updateMousePos);
    return () => window.removeEventListener("mousemove", updateMousePos);
}, []);

return (
    <div className='dresserPlane' style={{
        transform: `translateY(${mouseY.y/5}vh) rotateX(${mouseY.y-25}deg) rotateY(${mouseX.x+30}deg)`
    }} >

        <div className={`dCuboid dBody`}>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
        </div>

        <div className={`dCuboid dDrawer`} onClick={()=>props.setActiveDrawer('personal shopper')} style={{pointerEvents: `${props.smol ? 'none' : 'initial'}`}}>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'>
                <p>personal shopper</p>
            </div>
            <div className='dCuboid__side'></div>
        </div>

        <div className={`dCuboid dDrawer`} onClick={()=>props.setActiveDrawer('closet cleanouts')} style={{pointerEvents: `${props.smol ? 'none' : 'initial'}`}}>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'>
                <p>closet cleanouts</p>
            </div>
            <div className='dCuboid__side'></div>
        </div>

        <div className={`dCuboid dDrawer`} onClick={()=>props.setActiveDrawer('inquire')} style={{pointerEvents: `${props.smol ? 'none' : 'initial'}`}}>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'>
                <p>inquire</p>
            </div>
            <div className='dCuboid__side'></div>
        </div>

        <div className={`dCuboid dDrawer`} onClick={()=>props.setActiveDrawer('wardrobe stylist')} style={{pointerEvents: `${props.smol ? 'none' : 'initial'}`}}>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'>
                <p>wardrobe stylist</p>
            </div>
            <div className='dCuboid__side'></div>
        </div>

        <div className={`dCuboid dLeg`}>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
        </div>

        <div className={`dCuboid dLeg dLeg2`}>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
        </div>

        <div className={`dCuboid dLeg dLeg3`}>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
        </div>

        <div className={`dCuboid dLeg dLeg4`}>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
            <div className='dCuboid__side'></div>
        </div>
    </div>
);
};

export default CuboidDresser;